
import {
  DefaultHttpClient,
  IChangePasswordRequest,
  IHttpClient,
  ILoginRequest,
} from "@/interfaces/IHttpClient";
import { defineComponent, inject } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ChangePassword",
  components: {},
  data() {
    return {
      changePassword: {
        password: "",
        token: "",
        key: "",
      } as IChangePasswordRequest,
      confirmPassWord: "" as string,
      httpClient: null as IHttpClient | null,
      isLoading: false as boolean,
      isLoginFail: false as boolean,
      errorMessage: "" as string,
    };
  },
  setup() {
    const route = useRoute()
    return {
      httpClient: inject<IHttpClient>(DefaultHttpClient),
      token: route.query.token
    };
  },
  async created() {},
  mounted() {},
  methods: {
    async send() {
      this.isLoading = true;
      this.errorMessage = "";
      if (!this.isValidPassword()) {
        this.errorMessage = "正しく入力してください";
        this.isLoading = false;
        return;
      }
      this.changePassword.token = String(this.token);
      const result = await this.httpClient?.changePassword(this.changePassword);
      if (result !== null) {
        location.href = "/login";
      } else {
        this.errorMessage = "エラー";
        this.isLoading = false;
      }
    },
    isValidPassword(): boolean {
      if (this.changePassword.password == "" && this.confirmPassWord == "")
        return true;
      if (this.changePassword.password != this.confirmPassWord) {
        return false;
      }
      return true;
    },
  },
});
