import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main-container-wrapper"
}
const _hoisted_2 = { class: "container-fluid page-body-wrapper" }
const _hoisted_3 = { class: "main-panel" }
const _hoisted_4 = { class: "content-wrapper" }
const _hoisted_5 = { class: "container-fluid" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "main-content- h-100vh"
}
const _hoisted_8 = { class: "container h-100" }
const _hoisted_9 = { class: "row h-100 align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (!_ctx.isLogin)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Header),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Sidebar),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_view)
              ]),
              _createVNode(_component_Footer)
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        (_ctx.isReady)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_router_view)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
}