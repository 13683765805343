import { createApp  } from 'vue'
import App from './App.vue'
import { IBasicUtil, DefaultBasicUtil } from './interfaces/IBasicUtil';
import { DefaultHttpClient, IHttpClient } from './interfaces/IHttpClient';
import router from './router'
import { HttpClient } from './services/httpClient';
import { BasicUtil } from './services/BasicUtil';
import store, { storeKey } from "./services/Store";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Toaster from '@meforma/vue-toaster';

const basicUtil = new BasicUtil()
const app = createApp(App).use(router).use(VueLoading)
app.use(Toaster);
app.provide(storeKey, store);
app.provide<IHttpClient>(DefaultHttpClient, new HttpClient(basicUtil));
app.provide<IBasicUtil>(DefaultBasicUtil, basicUtil);

app.mount("#app");