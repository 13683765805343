
import { DefaultBasicUtil, IBasicUtil, Role } from "@/interfaces/IBasicUtil";
import {
  IHttpClient,
  DefaultHttpClient,
  IUserResponse,
} from "@/interfaces/IHttpClient";
import { defineComponent, inject } from "vue";
import { useRoute } from "vue-router";
import { ITokenResponse } from "../interfaces/IHttpClient";
import { store, storeKey } from "../services/Store";
// import VSelect from "vue-select";
export default defineComponent({
  name: "UserForm",
  components: {},
  data() {
    return {
      currentUser: null as ITokenResponse | null | undefined,
      user: null as IUserResponse | null | undefined,
      isReady: false as boolean,
      confirmPassword: "" as string,
      invalidEmail: false as boolean,
      invalidPassword: false as boolean,
      roleList: {
        user: false as boolean,
        finance: false as boolean
      }
    };
  },
  setup() {
    const route = useRoute();
    const { user } = inject(storeKey) as store;
    return {
      basicUtil: inject<IBasicUtil>(DefaultBasicUtil),
      httpClient: inject<IHttpClient>(DefaultHttpClient),
      userId: route.params.id ? Number(route.params.id) : 0,
      currentUser: user,
    };
  },
  async created() {
    if (
      !this.currentUser ||
      this.currentUser == null ||
      !this.basicUtil?.hasRole(this.currentUser.role, Role.User)
    ) {
      return (location.href = "/");
    }
    if (this.userId > 0) {
      this.user = await this.httpClient?.getUser(this.userId);
    } else {
      this.user = {
        id: undefined,
        password: "",
        companyId: Number(this.currentUser?.companyId),
        level: 1,
        userName: "",
        firstName: "",
        lastName: "",
        role: "00000000",
        roleList: this.basicUtil.getRoleList("00000000")
      };
    }
    console.log(this.user)
    this.roleList = this.basicUtil.getRoleList(this.user!.role)
    this.isReady = true;
  },
  methods: {
    async submitClick() {
      let result: any;
      if (this.userId <= 0) {
        if (!this.validateEmail() || !this.validatePassword()) {
          return;
        }
        result = await this.httpClient?.addUser({
          companyId: Number(this.currentUser?.companyId),
          level: Number(this.user?.level),
          userName: String(this.user?.userName),
          firstName: String(this.user?.firstName),
          lastName: String(this.user?.lastName),
          password: String(this.user?.password),
          role: this.basicUtil!.setRoleList(this.roleList)
        });
      } else {
        result = await this.httpClient?.editUser(this.userId, {
          id: this.userId,
          companyId: Number(this.currentUser?.companyId),
          level: Number(this.user?.level),
          firstName: String(this.user?.firstName),
          lastName: String(this.user?.lastName),
          role: this.basicUtil!.setRoleList(this.roleList)
        });
      }
    },
    validateEmail(): boolean {
      var email =
        /^[A-Za-z0-9]{1}[A-Za-z0-9+_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      var result = email.test(String(this.user?.userName));
      this.invalidEmail = !result;
      return result;
    },

    validatePassword(): boolean {
      this.invalidPassword = !(this.user?.password === this.confirmPassword);
      if (this.user?.password == "" || this.invalidPassword) {
        this.invalidPassword = true;
        return false;
      }
      return !this.invalidPassword;
    },
  },
});
