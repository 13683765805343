import { InjectionKey } from "vue";
import { RoleList } from "./IBasicUtil";

export const DefaultHttpClient: InjectionKey<IHttpClient> = Symbol("api");
export interface IHttpClient {
  token: () => Promise<ITokenResponse | null>;
  passwordLogin: (request: ILoginRequest) => Promise<ILoginResponse|null>;
  verifytwofa: (request: ITwofaRequest) => Promise<ITwofaResponse>;
  getUsers: () => Promise<IUserResponse[]>;
  getUser: (id: number) => Promise<IUserResponse|null>;
  getCompany: (id: number) => Promise<ICompanyResponse|null>;
  addUser: (data: ICreateUserRequest) => Promise<IUserResponse|null>;
  editUser: (id: number, data: IUpdateUserRequest) => Promise<IUserResponse|null>;
  getProjects: () => Promise<IProject[]>;
  forgetPassword: (email: string) => Promise<IForgetResponse>;
  changePassword: (data: IChangePasswordRequest) => Promise<void>;
}

export interface ITokenResponse {
  id: number,
  companyId: number,
  level: number,
  userName: string,
  firstName: string,
  lastName: string,
  role: string
}

export interface ICreateUserRequest {
  companyId: number,
  level: number,
  userName: string,
  firstName: string,
  lastName: string,
  password: string,
  role: string
}

export interface IUpdateUserRequest {
  id: number,
  companyId: number,
  level: number,
  firstName: string,
  lastName: string,
  role: string
}

export interface IUserResponse {
  id: number|undefined,
  companyId: number,
  level: number,
  userName: string,
  firstName: string,
  lastName: string,
  password: string,
  role: string,
  roleList: RoleList
}

export interface ICompanyResponse {
  id: number,
  name: string,
}

export interface ILoginRequest {
  username: string
  password: string
}

export interface ILoginResponse {
  barcodeImageUrl: string
  userId: number
  manualKey: string
}

export interface ITwofaRequest {
  userId: number
  passcode: string
}

export interface ITwofaResponse {
  result: boolean
}

export interface IProject {
  id: number;
  companyId: number;
  name: string;
  url: string;
  metas: IMeta[];
  createdAt: string | null;
  updatedAt: string | null;
}

export interface IMeta {
  id: number;
  projectId: number;
  project: IProject;
  name: string;
  url: string;
  username: string;
  password: string;
  basicAuthUsername: string;
  basicAuthPassword: string;
  createdAt: string | null;
  updatedAt: string | null;
}

export interface IForgetResponse {
  key: string,
}

export interface IChangePasswordRequest {
  key: string;
  token: string;
  password: string;
}