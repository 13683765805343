import { ICompanyResponse, IHttpClient, ITokenResponse } from "@/interfaces/IHttpClient";
import { ref, computed, ComputedRef, InjectionKey } from "vue";

export interface store {
  user: ComputedRef<ITokenResponse>;
  company: ComputedRef<ICompanyResponse>;
  getUser: (httpClient: IHttpClient) => void;
}

export const storeKey: InjectionKey<store> = Symbol("store");

const _user = ref({
  id: 0,
  companyId: 0,
  level: 1,
  userName: "",
  firstName: "",
  lastName: "",
  role: ""
});

const _company = ref({
  id: 0,
  name: "",
});


const getUser = async (httpClient: IHttpClient) => {
  const data = await httpClient.token()
  if (data == null) return
  const company = await httpClient.getCompany(data.companyId)
  _user.value = data
  if (company != null) _company.value = company
};

const user = computed(() => _user.value);
const company = computed(() => _company.value);
export default { user, company, getUser };