
import { DefaultBasicUtil, IBasicUtil, Role } from "@/interfaces/IBasicUtil";
import {
  IHttpClient,
  DefaultHttpClient,
  IUserResponse,
} from "@/interfaces/IHttpClient";
import { defineComponent, inject } from "vue";
import { store, storeKey } from "../services/Store";
// import VSelect from "vue-select";
export default defineComponent({
  name: "User",
  components: {  },
  data() {
    return {
      users: [] as IUserResponse[]|undefined,
    };
  },
  setup() {
    const { user } = inject(storeKey) as store;
    return {
      httpClient: inject<IHttpClient>(DefaultHttpClient),
      currentUser: user,
      basicUtil: inject<IBasicUtil>(DefaultBasicUtil),
    };
  },
  async created() {
    if (
      !this.currentUser ||
      this.currentUser == null ||
      !this.basicUtil?.hasRole(this.currentUser.role, Role.User)
    ) {
      return (location.href = "/");
    }
    const users = await this.httpClient?.getUsers();
    this.users = users!.filter(u => {
      return u.id! > 1
    })

    this.users.forEach(u => {
      u.roleList = this.basicUtil!.getRoleList(u.role)
    })
  },
  methods: {},
});
