import { InjectionKey, Ref } from "vue";

export const DefaultBasicUtil: InjectionKey<IBasicUtil> = Symbol("util");
export interface IBasicUtil {
  
  hasRole: (roleStr: string, role: Role) => boolean;

  getRoleList: (roleStr: string) => RoleList;
  
  setRoleList: (roles: RoleList) => string;

  showFullLoading: () => void;
  /**
   * loading表示
   * component: VueComponent(this)
   * isFullScreen: 全画面表示か否か
   */
  showLoading: (component: any, isFullScreen: boolean) => void;

  /**
   * loading非表示
   */
  hideLoading: () => void;

  showToast: (vm: any, message: string) => void
}

export enum Role {
  User = 0,
  Finance = 1
}

export interface RoleList {
  user: boolean,
  finance: boolean
}