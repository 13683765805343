
import { defineComponent, inject, onMounted, provide, ref } from "vue";
import { DefaultHttpClient, IHttpClient } from "./interfaces/IHttpClient";
import { HttpClient } from "./services/httpClient";
import Header from "./components/Header.vue";
import Sidebar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";
import { useRoute, useRouter } from "vue-router";
import { store, storeKey } from "./services/Store";
export default defineComponent({
  components: {
    Header,
    Sidebar,
    Footer,
  },
  data() {
    return {
      isLogin: true as boolean,
      isReady: false as boolean,
    };
  },
  setup() {
    const httpClient = inject<IHttpClient>(DefaultHttpClient) as IHttpClient;
    const { user, getUser } = inject(storeKey) as store;

    const isLogin = ref(true);
    const isReady = ref(false);
    onMounted(async () => {
      await getUser(httpClient);
      if ((location.pathname.match(/\/login/) == null && location.pathname.match(/\/change-password/) == null) && user.value.id == 0) {
        return location.href = "/login";
      }
      isLogin.value = location.pathname.match(/\/login/) !== null || location.pathname.match(/\/change-password/) !== null;
      isReady.value = true
    });
    return {
      isLogin,
      isReady
    };
  },
  created() {},
  mounted() {},
});
