import { IBasicUtil } from "@/interfaces/IBasicUtil";
import { IHttpClient, ILoginResponse, ITokenResponse, ILoginRequest, ITwofaRequest, ITwofaResponse, IUserResponse, ICreateUserRequest, IUpdateUserRequest, ICompanyResponse, IProject, IForgetResponse, IChangePasswordRequest } from "@/interfaces/IHttpClient";
import axios, { AxiosInstance, AxiosResponse } from "axios";

export class HttpClient implements IHttpClient {
  private axiosInstance: AxiosInstance | undefined
  private axiosNoAuthNoLoadingInstance: AxiosInstance | undefined
  private basicUtil: IBasicUtil | undefined
  constructor(basicUtil: IBasicUtil) {
    this.basicUtil = basicUtil
    this.axiosInstance = this.getBasicInstance()
    this.axiosNoAuthNoLoadingInstance = this.getBasicInstance(false, false)
  }

  private getBasicInstance(isSetLoading: boolean = true, isSetAuth: boolean = true): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: true,
    });
    axiosInstance.interceptors.request.use((request) => {
      if (isSetLoading) this.basicUtil?.showFullLoading()
      return request;
    });
    axiosInstance.interceptors.response.use(
      (response) => {
        if (isSetLoading) this.basicUtil?.hideLoading()
        return response;
      },
      (error) => {
        if (isSetAuth) this.checkAuth(error.response)
        if (isSetLoading) this.basicUtil?.hideLoading()
        throw error;
      }
    );
    return axiosInstance
  }
  public async token() {
    return this.axiosNoAuthNoLoadingInstance!.get<ITokenResponse>("auth/token").then((res: AxiosResponse<ITokenResponse>) => {
      return res.data
    }).catch((e) => {
      return null
    })
  }

  

  public async passwordLogin(request: ILoginRequest) {
    return this.axiosNoAuthNoLoadingInstance!.post<ILoginResponse>("auth/password-login",
      request).then((res: AxiosResponse<ILoginResponse>) => {
        return res.data
      }).catch(e => {
        return null
      })
  }

  public async verifytwofa(request: ITwofaRequest) {
    return this.axiosNoAuthNoLoadingInstance!.post<ITwofaResponse>("auth/verify-twofa",
      request).then((res: AxiosResponse<ITwofaResponse>) => {
        return res.data
      })
  }

  public async getUsers() {
    return this.axiosInstance!.get<IUserResponse[]>("user",
      {
        withCredentials: true,
      }).then((res: AxiosResponse<IUserResponse[]>) => {
        return res.data
      }).catch((e) => {
        return []
      })
  }

  public async getUser(id: number) {
    return this.axiosInstance!.get<IUserResponse>("user/" + String(id)).then((res: AxiosResponse<IUserResponse>) => {
      return res.data
    }).catch((e) => {
      return null
    })
  }

  public async addUser(data: ICreateUserRequest) {
    return this.axiosInstance!.post<IUserResponse>("user",
      data).then((res: AxiosResponse<IUserResponse>) => {
        return res.data
      }).catch((e) => {
        return null
      })
  }

  public async editUser(id: number, data: IUpdateUserRequest) {
    return this.axiosInstance!.put<IUserResponse>("user/" + String(id), data).then((res: AxiosResponse<IUserResponse>) => {
      return res.data
    }).catch((e) => {
      return null
    })
  }

  public async getCompany(id: number) {
    return this.axiosInstance!.get<ICompanyResponse>("company/" + String(id)).then((res: AxiosResponse<ICompanyResponse>) => {
      return res.data
    }).catch((e) => {
      return null
    })
  }

  public async getProjects() {
    return this.axiosInstance!.get<IProject[]>("project",
    {
      withCredentials: true,
    }).then((res: AxiosResponse<IProject[]>) => {
      return res.data
    }).catch((e) => {
      return []
    }) 
  }

  private checkAuth(response: any) {
    if (response.status == 401) {
      location.href = "/login"
    }
  }

  async forgetPassword(email: string) {
    return axios.post(
      'https://prod-api-authentication-nj7pdwnyxa-an.a.run.app/user/forget-password',
      { "userName": email }
    ).then((res: AxiosResponse<IForgetResponse>) => {
      return res.data
    })
  }

  async changePassword(data: IChangePasswordRequest) {
    return axios.post(
      'https://prod-api-authentication-nj7pdwnyxa-an.a.run.app/user/change-password',
      data
    ).then((res: any) => {
      return res.data
    }).catch(_ => {
      return null
    })
  }
}