
import { DefaultBasicUtil, IBasicUtil } from "@/interfaces/IBasicUtil";
import {
  DefaultHttpClient,
  IHttpClient,
  IProject,
} from "@/interfaces/IHttpClient";
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "Project",
  components: {},
  data() {
    return {
      projects: [] as IProject[] | undefined,
    };
  },
  setup() {
    return {
      httpClient: inject<IHttpClient>(DefaultHttpClient),
      basicUtil: inject<IBasicUtil>(DefaultBasicUtil),
    };
  },
  async created() {
    this.projects = await this.httpClient?.getProjects();
  },
  methods: {
    copyPassword(e: any, pass: string) {
      navigator.clipboard.writeText(pass)
        .then(() => {
          this.basicUtil?.showToast(this, "コピーしました。")
        })
    },
  },
});
