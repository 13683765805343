import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { HttpClient } from "../services/httpClient";
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ChangePassword from '../views/ChangePassword.vue'
import Project from '../views/Project.vue'
import User from '../views/User.vue'
import UserForm from '../views/UserForm.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/project',
    name: 'Project',
    component: Project
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/user/add',
    name: 'UserAdd',
    component: UserForm
  },
  {
    path: '/user/edit/:id',
    name: 'UserUpdate',
    component: UserForm
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve(async (to, from, next) => {
  // const client = new HttpClient()
  // const authResult = await client?.token()
  // if (to.path == '/login') {
  //   if (authResult !== null) {
  //     window.location.href = '/'
  //     return
  //   } else {
  //     next()
  //   }
  // } else {
  //   if (authResult === null) {
  //     window.location.href = '/login'
  //     return
  //   } else {
  //     next()
  //   }
  // }
  next()
})


export default router
