
import { defineComponent, inject } from "vue";
import { store, storeKey } from "../services/Store";
import { DefaultBasicUtil, IBasicUtil, Role } from "@/interfaces/IBasicUtil";
export default defineComponent({
  name: "Header",
  props: {},
  data() {
    const { user } = inject(storeKey) as store;
    return {
      currentUser: user,
      basicUtil: inject<IBasicUtil>(DefaultBasicUtil),
    };
  },
});
