
import { defineComponent, inject } from "vue";
import { store, storeKey } from "../services/Store";

export default defineComponent({
  name: "Home",
  components: {},
  setup () {
    const { user, company } = inject(storeKey) as store;
    return {
      currentUser: user,
      currentCompany: company
    };
  },
  methods: {

  }
});
