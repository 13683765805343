
import {
  DefaultHttpClient,
  IHttpClient,
  ILoginRequest,
} from "@/interfaces/IHttpClient";
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "Login",
  components: {},
  data() {
    return {
      userId: 0 as number,
      auth: {
        username: "",
        password: "",
      } as ILoginRequest,
      httpClient: null as IHttpClient | null,
      is2fa: false as boolean,
      passcode: "" as string,
      barcodeImageUrl: "" as string,
      redirectUrl: (location.protocol + "//" + location.host) as string,
      endpoint: process.env.VUE_APP_API_URL as string,
      errorMessage: "" as string,
      errorTwoFaMessage: "" as string,
      isLoading: false as boolean,
      isLoginFail: false as boolean,
      isForget: false as boolean,
      forgetMail: "" as string,
      hasForgetKey: false as boolean,
      forgetKey: "" as string,
      manualKey: "" as string
    };
  },
  setup() {
    return {
      httpClient: inject<IHttpClient>(DefaultHttpClient),
    };
  },
  async created() {},
  mounted() {},
  methods: {
    async login() {
      this.isLoading = true;
      this.errorMessage = "";
      const result = await this.httpClient?.passwordLogin(this.auth);
      if (result !== null) {
        this.is2fa = true;
        this.barcodeImageUrl = result.barcodeImageUrl;
        this.manualKey = result.manualKey;
        this.userId = result.userId;
      } else {
        this.errorMessage = "メールアドレスまたはパスワードが間違っています。";
      }
      this.isLoading = false;
    },
    async verify() {
      this.errorTwoFaMessage = "";
      this.isLoading = true;
      const result = await this.httpClient?.verifytwofa({
        userId: this.userId,
        passcode: this.passcode,
      });
      if (result.result) {
        (document.getElementById("from") as any).submit();
      } else {
        this.errorTwoFaMessage = "認証に失敗しました";
        this.isLoading = false;
      }
    },
    async forget() {
      if (this.forgetMail == "") return;
      this.isLoading = true;
      this.errorMessage = "";
      const result = await this.httpClient?.forgetPassword(this.forgetMail);
      if (result !== null) {
        this.hasForgetKey = true;
        this.forgetKey = result.key;
      } else {
        this.errorMessage = "";
      }
      this.isLoading = false;
    },
  },
});
